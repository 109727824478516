<template>
  <div class="tab-container">
    <div class="tab-container-keys no-print">
      <ul class="nav nav-pills">
        <li class="nav-item" v-for="(key, index) in keys" :key="typeof key === 'string' ? key : key.label"
          :id="key.id ? key.id : key.label">
          <a :style="cssVars" class="nav-link item"
            v-bind:class="{ active: isActive(index), disabled: key.disabled, 'd-none': key.hidden }"
            @click="changeIndex(index)">
            {{ typeof key === 'string' ? key : key.label }}
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tab',
  props: {
    keys: Array,
    index: Number,
    fontSize: { type: String, default: '' }
  },
  computed: {
    cssVars() {
      return {
        '--font': this.fontSize
      };
    }
  },
  data() {
    return {
      currentIndex: this.index || 0
    };
  },
  methods: {
    isActive(index) {
      return this.currentIndex === index;
    },
    changeIndex(index) {
      this.currentIndex = index;
      this.$emit('onChange', index);
    }
  },
  watch: {
    index: function () {
      this.currentIndex = this.index;
    }
  }
};
</script>

<style scoped lang="scss">
.tab-container {
  background-color: white;
  border-radius: 10px;
  padding: 5px;


  .tab-container-keys {
    padding-bottom: 15px;
  }

  ul li a.item {
    font-size: 14px;

    margin: 2px 3px;
    padding: 0 10px;

    border: 1px solid #D9DFF2;
    border-radius: 100px;

    padding: 6px 12px 6px 12px;
    color: #304388;
    cursor: pointer;


    &.active {
      background-color: #F4F5FB;
    }
  }
}
</style>
